import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenStoreKey } from '../AppConstants';
import AppContext from '../AppContext';
import SignIn from '../components/Auth/SignIn';
import SpinnerDialog from '../components/Loader/Spinner';
import them from '../themes';
import NavigationScroll from './NavigationScroll';
import Header from './SideMiniDrawer';
import { getLoginUserDetailsByToken } from '../store/actions/App.Action';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '&.boxed': {
            maxWidth: 1280,
            margin: '0 auto',
        },
        '&.container': {
            '&.container': {
                maxWidth: 1120,
                width: '100%',
                margin: '0 auto',
            },
        },
    },
    content: {
        overflow: 'auto',
        flexDirection: 'column',
        '-webkit-overflow-scrolling': 'touch',
        backgroundColor: '#f9f9f9',
    },
}));

const Layout = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    const { isAppLoader } = useSelector((state: any) => state.app);
    const { user } = useSelector((state: any) => state.auth);

    const [count, setCount] = useState(0);
    const setToken = async () => {
        const token = await getAccessTokenSilently();
        if (token) {
            localStorage.setItem(TokenStoreKey, token);
            if (!user && !isAppLoader && localStorage.getItem(TokenStoreKey) && count <= 4) {
                dispatch(getLoginUserDetailsByToken())
                setCount(count + 1)
            }
        }
    }

    if (isAuthenticated) {
        setToken();
    }

    return (
        <ThemeProvider theme={them}>
            <CssBaseline />
            <AppContext.Consumer>
                {(appContext: any) => (
                    <NavigationScroll>
                        <div className={classes.root}>
                            {(isAppLoader || isLoading) && <SpinnerDialog />}
                            {!isLoading && !isAuthenticated && <SignIn />}
                            {(!isLoading && isAuthenticated) &&
                                <Header logout={logout} routes={appContext.routes} history={props.history} />
                            }
                        </div>
                    </NavigationScroll>
                )}
            </AppContext.Consumer>
        </ThemeProvider>
    );
}

export default Layout;
