import { RouteAccess } from '../AppConstants';
import SignIn from '../components/Auth/SignIn';
import OrderHistory from '../pages/orders/OrderHistory';
import Profile from '../pages/profile/Profile';
import ProductForm from '../pages/unwired-product/ProductForm';
import UnwiredAdminProductView from '../pages/unwired-product/UnwiredAdminProductView';
const routes = [
  {
    path: '/login',
    component: SignIn,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/',
    component: SignIn,
    exact: true,
    access: RouteAccess.All,
  },

  {
    path: '/profile',
    component: Profile,
    exact: true,
    access: RouteAccess.All,
  },

  {
    path: '/unwired-product',
    component: UnwiredAdminProductView,
    exact: true,
    access: RouteAccess.Administrator,
  },
  {
    path: '/unwired-product/add',
    component: ProductForm,
    exact: true,
    access: RouteAccess.AdminAndContributor,
  },
  {
    path: '/unwired-product/edit/:id',
    component: ProductForm,
    exact: true,
    access: RouteAccess.AdminAndContributor,
  },
  {
    path: '/order-history',
    component: OrderHistory,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '*',
    component: SignIn,
    exact: true,
    access: RouteAccess.All,
  },
];

export default routes;
