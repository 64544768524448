import { withAuth0 } from '@auth0/auth0-react';
import Lock from '@mui/icons-material/LockOutlined';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import React from 'react';
import { ReactComponent as EnglandCrest } from '../../assets/icons/unwired_icon.svg';
import { ReactComponent as PPSLogo } from '../../assets/icons/unwired_logo.svg';
const styles = {
	app: {
		width: '100%',
		height: '100%',
	},
	crest: {
		position: 'absolute',
		animationName: '$shrink',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		bottom: 30,
		transition: 'all .3s',
		left: 55,
		'& path': {
			fill: '#04264C'
		}
	},
	logo: {
		position: 'absolute',
		height: 125,
		opacity: 0,
		animationName: '$fadeLogo',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		marginLeft: 30,
		bottom: 30,
		right: 100,
		'& path': {
			fill: '#04264C'
		}
	},
	loginButton: {
		opacity: 0,
		animationName: '$fade',
		animationDelay: '1.5s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
	},
	'@keyframes shrink': {
		to: { height: 125, left: 0 }
	},
	'@keyframes fadeLogo': {
		to: { opacity: 1, right: 0 }
	},
	'@keyframes fade': {
		to: { opacity: 1 }
	},
};

class SignIn extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			email: '',
			password: '',
			override: '',
			loader: false
		};
	}
	onChange = (value: any, field: any) => {
		this.setState({ [field]: value.map((option: any) => option.value) });
	}

	render() {
		const { override, attributes, loader } = this.state;
		const { classes,auth0 } = this.props;
		return (
			<Box height={'100vh'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<div className="d-flex" style={{ position: 'relative', minHeight: 250, width: 547 }}>
					<EnglandCrest className={classes.crest} style={{ height: 250 }} />
					<PPSLogo className={classes.logo} />
				</div>
				<Button onClick={() => auth0?.loginWithRedirect()}
					variant="contained"
					color="primary"
					className={
						classes.loginButton
					}
					disabled={auth0?.isLoading}>
					{auth0?.isLoading ? <CircularProgress size={20} /> : (
						<React.Fragment>
							<Lock className="mr-1" />
							<span style={{ marginTop: 4, fontSize: 16 }}>SIGN IN</span>
						</React.Fragment>
					)}
				</Button>
			</Box>
		);
	}
}
export default withStyles(styles as any)(withAuth0(SignIn));
