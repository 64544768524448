import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Theme, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography } from '@mui/material';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import IFilterModel from '../../models/common/IFilterModel';
import { UnwiredSearch } from '../../components/search/UnwiredSearch';
import * as ProductAction from '../../store/actions/Product.Action';
import IProduct from '../../models/product/IProduct';

const classNames = require('classnames');
interface IPageProps {
    classes?: any,
    productList: IProduct[]
    getProductsByRange: (start: number, limit: number, paramName?: any, paramValue?: any, userId?: any) => void;
    user: any;
}
interface IPageState {
    productTablePage: number,
    productTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: IFilterMenuOptionsModel[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
}
class UnwiredAdminProductView extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            productTablePage: 0,
            productTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [{ "label": 'Product Name', "key": 'name' }],
            selectedFilterMenu: 'Product Name',
            selectedFilterMenuKey: 'name',
            searchBoxValue: '',
            searchBoxFilterKey: '',
        }
    }
    public async componentWillMount() {
        const { userId, userType } = this.props.user;
        this.props.getProductsByRange(0, 10, null, null, userType === 'C' ? userId : null);
    }

    private handleChangeownerPage = (event: any, page: number) => {
        console.log('')
    };
    private handleChangeTableRowsPerPage = async (event: any) => {
        this.setState({ productTableRowsPerPage: event.target.value, pageStartCount: event.target.value, productTablePage: 0 });
    };
    private loadNextPage = async () => {
        this.setState({ productTablePage: this.state.productTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.productTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ productTablePage: this.state.productTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.productTableRowsPerPage })
    }

    private editProductRecord = (product: any) => {
        this.props.history.push(`/unwired-product/edit/${product.productId}`);
    }

    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private heandleSerachBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        const { userId, userType } = this.props.user;
        this.props.getProductsByRange(0, this.state.productTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue, userType === 'C' ? userId : null);
    }
    private gotoProductPage = () => {
        this.props.history.push('/unwired-product/add')
    }
    public render(): JSX.Element {
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions,
            searchBoxValue, } = this.state;
        const { classes, productList } = this.props;
        return (<Paper className={classNames(classes.paper, classes.product, 'container')} elevation={5} square={false}>
            <UnwiredSearch handleClickListItem={this.handleClickListItem} anchorEl={anchorEl}
                handleClose={this.handleClose} filterMenuOptions={filterMenuOptions} selectedFilterMenuIndex={selectedFilterMenuIndex}
                handleMenuItemClick={this.handleMenuItemClick} selectedFilterMenu={selectedFilterMenu} searchBoxValue={searchBoxValue}
                handleCloseExportMenu={null} exportMenuOptions={null}
                handleClickExport={null} exportAnchorEl={null} selectedExportMenuIndex={null}
                handleDownloadMenuItemClick={null}
                buttonAddFunction={this.gotoProductPage} buttonAddLabel={'Add Product'}
                heandleSerachBox={this.heandleSerachBox} onEnterKeyPress={this.onEnterKeyPress} />
            <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
            <Typography component="div" className={classes.tabelContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tabelHeaderBg}>Image</TableCell>
                            <TableCell className={classes.tabelHeaderBg}>Product Name</TableCell>
                            <TableCell className={classes.tabelHeaderBg}>Price</TableCell>
                            <TableCell className={classes.tabelHeaderBg}>Stock</TableCell>
                            <TableCell className={classes.tabelHeaderBg}>Per Order Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(Array.isArray(productList) && productList.length > 0) ?
                            productList.slice(this.state.productTablePage * this.state.productTableRowsPerPage,
                                this.state.productTablePage * this.state.productTableRowsPerPage + this.state.productTableRowsPerPage).map((product: IProduct, productIndex: number) => {
                                    return (
                                        <TableRow key={`Prod${productIndex}`} onDoubleClick={() => this.editProductRecord(product)}>
                                            <TableCell><img style={{ height: '40px', width: '40px' }} alt="" src={(product.images && product.images.length) ? ('data:image/png;base64,' + product.images[0].image) : ''} /></TableCell>
                                            <TableCell>{product.name}</TableCell>
                                            <TableCell>{product.price}</TableCell>
                                            <TableCell>{product.stock}</TableCell>
                                            <TableCell>{product.maxPerOrderQuantity}</TableCell>
                                        </TableRow>
                                    );
                                }) : null}
                    </TableBody>
                </Table>
                {(Array.isArray(productList) && productList.length > 0) &&
                    <TablePagination
                        component="div"
                        count={productList.length}
                        rowsPerPage={this.state.productTableRowsPerPage}
                        page={this.state.productTablePage}
                        backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                        onPageChange={this.handleChangeownerPage}
                        onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                    />}
            </Typography>
        </Paper>)
    }

}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    product: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tabelContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    attachementIcon: {
        cursor: 'pointer',
        marginRight: '10px'
    },
    dialogContainer: {
        width: '50%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tabelHeaderBg: {
        backgroundColor: '#f1b665a8',
        fontWeight: 700
    }
})
const mapStateToProps = (state: any) => {
    return {
        productList: state.product?.adminProductsList,
        user: state.auth?.user
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getProductsByRange: ProductAction.getProductsByRange
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(UnwiredAdminProductView as any)) as any);