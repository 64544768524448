import { Action } from "redux";

export enum ActionType {
    OPEN_DRAWER,
    CLOSE_DRAWER,
    OPEN_ALERT,
    CLOSE_ALERT,
    OPEN_SPINNER,
    CLOSE_SPINNER,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,

    GET_CUSTOMERS,
    GET_DEVICES,
    GET_USERS,
    SET_FORGOT_PASSWORD,

    EDIT_CUSTOMER_DETAILS,
    EDIT_DEVICE_DETAILS,
    SET_CUSTOMER_SELECT_BOX_VALUE,
    EDIT_USER_DETAILS,
    GET_RECIPIENTS_USERS,
    GET_USER_SELECTED,
    OPEN_CONFIRM_BOX,
    CLOSE_CONFIRM_BOX,
    IS_VALID_TOKEN_REGISTRATION,
    GET_PU_DEVICE_DASHBOARD_DETAILS,
    GET_OU_DEVICE_DASHBOARD_DETAILS,
    GET_OU_CUSTOMER_DASHBOARD_DETAILS,
    GET_LOCATION_MARKERS,
    GET_DASHBOARD_NOTIFICATIONS,
    GET_ATTACHMENT_LIST,
    GET_SENSOR_READINGS,
    GET_SENSOR_DETAILS_BY_DEVICE_ID,
    GET_OWNERS,
    EDIT_OWNER_DETAILS,
    USER_PROFILE_DETAILS,
    GET_DASHBOARD_ALERTS,
    GUEST_LOGIN_SUCCESS,

    // Product Related
    GET_PRODUCT_LIST,
    SELECTED_PRODUCT,
    REMOVE_SELECTED_PRODUCT_FORM_STORE,
    ADD_CART_POP_WINDOW_OPEN,
    ADD_CART_POP_WINDOW_CLOSE,
    OPEN_SIGN_IN_MODEL,
    OPEN_SIGN_UP_MODEL,
    CLOSE_SIGN_UP_MODEL,
    OPEN_PRODUCT_DETAIL_PAGE_MODEL,
    CLOSE_PRODUCT_DETAIL_PAGE_MODEL,
    CLOSE_SIGN_IN_MODEL,
    ORDER_SET,
    ORDER_SET_DEFAULT,

    ADD_TO_ORDERED,
    
    ADD_PRODUCT,
    GET_MENU_LIST,
    GET_ORDERS_HISTORY,
    GET_ORDERS_HISTORY_SUCCESS,
    GET_ORDERS_HISTORY_FAILURE,
    EDIT_CATEGORY_DETAILS,
    EDIT_PRODUCT_DETAILS,
    SET_CATEGORY_SELECT_BOX_VALUE,
   
    EDIT_CART_PRODUCT_DETAILS,
    REMOVE_CART_PRODUCT_DETAILS,
    SHOW_LOADER,
    HIDE_LOADER,

    USER_PUT_REQUEST,
    USER_PUT_SUCCESS,
    USER_PUT_FAILURE,

    ADD_TO_CART,
    GET_CART_DETAILS_REQUEST,
    GET_CART_DETAILS_FAILURE,
    GET_CART_DETAILS_SUCCESS,
    
    GET_ORDER_DETAILS_REQUEST,
    GET_ORDER_DETAILS_FAILURE,
    GET_ORDER_DETAILS_SUCCESS,


    REMOVE_ITEM_FROM_CART,
    REMOVE_ALL_ITEM_FROM_CART,
   

    GET_LOGIN_USER_DETAILS_REQUEST,
    GET_LOGIN_USER_DETAILS_SUCCESS,
    GET_LOGIN_USER_DETAILS_FAILURE,

    GET_PRODUCTS_LIST_REQUEST,
    GET_PRODUCTS_LIST_SUCCESS,
    GET_PRODUCTS_LIST_FAILURE,

    GET_CATEGORY_LIST_REQUEST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAILURE,

    GET_PRODUCT_BY_ID_REQUEST,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_FAILURE,

    GET_PRODUCT_BY_CATEGORY_ID_REQUEST,
    GET_PRODUCT_BY_CATEGORY_ID_SUCCESS,
    GET_PRODUCT_BY_CATEGORY_ID_FAILURE,

    GET_SENSOR_LIST,
    GET_SMT_MCU_LIST,


}

export interface IAppAction extends Action<ActionType> {
    payload?: any;
}