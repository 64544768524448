import { logoutSessionExpire } from "./AxiosUtils";
import axios from "axios";
import { TokenStoreKey } from "../AppConstants";

export let fileUpload = async ({ method = "POST", formData = new FormData(), token = '', baseURL = 'normal', apiEndPoint = '' }) => {
    return  new Promise(async (resolve, reject) => {
        const APIInstance:any = axios.create({
            headers: {
                "Accept": '*/*',
                'Content-Type': `multipart/form-data`,
                'Authorization': `Bearer ${localStorage.getItem(TokenStoreKey)}`
            },
        });
        await APIInstance[method](`${process.env.REACT_APP_BASE_URL + apiEndPoint}`, formData ? formData : '').then(async (response: any) => {
            if (token === 'string') {
                resolve(response.data);
            } else {
                 resolve(statusHelper(response))
            }
        }).catch(async (error: any) => {
            try {
                if (error.response) {
                     reject(statusHelper(error.response))
                } else {
                    reject(error)
                }
            }
            catch (err) {
                console.error(err)
                reject(err)
            }
        })
    })
}

export let downloadFile = async ({ method = "get", body = "", token = '', baseURL = 'normal', apiEndPoint = '', fileName = "" }) => {
    return  new Promise(async (resolve, reject) => {
        const APIInstance:any = axios.create({
            headers: {
                "Accept": '*/*',
                'Authorization': `Bearer ${localStorage.getItem(TokenStoreKey)}`
            },
            responseType: 'blob',
        });
        await APIInstance[method](`${process.env.REACT_APP_BASE_URL + apiEndPoint}`).then(async (response: any) => {
            resolve(response);
        }).catch(async (error: any) => {
            try {
                if (error.response) {
                     reject(statusHelper(error.response))
                } else {
                    reject(error)
                }
            }
            catch (err) {
                console.error(err)
                reject(err)
            }
        })
    })
}
export const statusHelper = async (response: any) => {
    if (response.status === 401) {
        await logoutSessionExpire();
        // history.push('/account/login');
        window.location.reload()
    }
    if (response.status === 200) {
        return {
            status: response.status,
            ...response.data
        }
    } else {
        return {
            ...response.data
        }
    }
}