import * as React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormControl,Theme, InputLabel, Input, Button, Grid, Typography, Container, TextField, Paper } from '@mui/material';
import SimpleReactValidator from 'simple-react-validator';
import * as UserActions from '../../store/actions/User.Actions';
import { toast } from 'react-toastify';
import { PhoneNumberMaskInput } from '../../components/mask-input/PhoneNumberMaskInput';
import IUser from '../../models/user/IUser';
const image = require('../../assets/images/profile.png')
interface IPageProps {
    classes: any,
    history?: any
    userProfileData?: IUser,
    getUserProfile: () => any;
    putUpdateUserById: (userId: string, body: string) => any;
    resetProfileStore: () => void;
}
interface IPageState extends IUser{
    isEnableEdit: boolean,
    isEditPassword: boolean,
    confirmPassword?: string,
}

const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
class ProfilePage extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            isEnableEdit: false,
            isEditPassword: false,
            userId:'',
        }
    }
    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    public componentWillMount = async () => {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
    }
    public componentDidMount = async () => {
        
            await this.props.getUserProfile().then((response: any) => {
                this.setState({ ...response.data })
            }).catch((error: any) => {
                toast("User Not found")
                this.props.history.push('/products');
            });
        
    }

    public componentWillUnmount() {
        this.props.resetProfileStore();
    }
    private handleSubmit = (event: any) => {
        event.preventDefault();
        const { password, confirmPassword, isEditPassword } = this.state;
        if (!isEditPassword) {
            this.validator.message('password', password, '');
            this.validator.message('confirm password', confirmPassword, '')
        }
        if (this.validator.allValid()) {
            if (isEditPassword) {
                if ((password === confirmPassword) && (password && reg.test(password))) {
                    const userDetails: any = Object.assign({}, this.state);
                    delete userDetails.isEditPassword;
                    delete userDetails.isEnableEdit
                    delete userDetails.confirmPassword
                    this.updateUserDetails(this.state.userId, userDetails)
                }
            } else {
                const userDetails: any = Object.assign({}, this.state);
                delete userDetails.isEditPassword;
                delete userDetails.isEnableEdit
                delete userDetails.confirmPassword
                delete userDetails.password
                this.updateUserDetails(this.state.userId, userDetails)
            }
        } else {
            this.validator.showMessages();
        }
    }

    private updateUserDetails = async (userId: any, userDetails: any) => {
        userDetails.mobileNumber = '+' + userDetails.mobileNumber.replace(/\D/g, "");
        userDetails.landlineNumber = '+' + userDetails.landlineNumber.replace(/\D/g, "");
        await this.props.putUpdateUserById(userId, JSON.stringify(userDetails)).then((response: any) => {
            if (response.status.success === "SUCCESS") {
                
                this.setState({ isEnableEdit: false, isEditPassword: false, password: '', confirmPassword: '', });
            }
        }).catch((error: any) => {
            console.error(error);
        });
    }
    private handleCancel = () => {
        this.setState({ isEnableEdit: false, isEditPassword: false, password: '', confirmPassword: '', });
        this.componentDidMount();
    }
    public render() {
        const { classes } = this.props;
        const { firstname, lastname, email, mobileNumber, landlineNumber, password, isEnableEdit, isEditPassword, confirmPassword } = this.state;
        return (<Typography component="div" className={classes.container}>
            <Grid container={true} className={classes.alignGridMobileView}>
                <Grid item={true} xs={12} sm={12} md={4} style={{ marginBottom: '10px' }}>
                    <Paper style={{ minHeight: 500, textAlign: 'center' }} elevation={5} square={false}>
                        <Typography component="div" style={{ paddingTop: '30px' }}>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img style={{ width: '200px', height: "200px", verticalAlign: 'middle', borderRadius: '50%' }} src={image} alt="..." />
                            </a>
                            <Typography style={{ marginTop: '12px', fontWeight: 400 }} component="h5">{(localStorage.getItem('userType') && (localStorage.getItem('userType') === "U" ? 'USER' : localStorage.getItem('userType') === "AU" ? 'ADMIN USER' : localStorage.getItem('userType') === "PU" ? 'POWER ADMIN' : localStorage.getItem('userType') === "OU" ? 'OWNER USER' : ''))}</Typography>
                            <Typography style={{ marginTop: '12px', fontWeight: 700 }} component="h3" >{localStorage.getItem('fullName') ? localStorage.getItem('fullName') : ''}</Typography>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={8} className={classes.alignCard} style={{ marginTop: '33px', marginBottom: '20px' }}>
                    <Paper style={{ minHeight: 500 }} elevation={5} square={false}>
                        <Grid item={true} xs={12} sm={12}>
                            <Grid container={true} spacing={4}>
                                <Grid item={true} xs={12} sm={12}>
                                    <Typography variant="subtitle1" align='center' style={{ paddingTop: '10px' }} component="h6" >Profile Information</Typography>
                                </Grid>
                                {/* <Grid item={true} xs={12} sm={12} style={{ textAlign: 'end', padding: 0, paddingRight: 26 }}>
                                    <Button
                                        onClick={(event) => isEditPassword ? this.setState({ isEditPassword: false, password: '', confirmPassword: '', }) : this.setState({ isEditPassword: true, isEnableEdit: true })}
                                        variant="text"
                                        color={!isEditPassword ? "primary" : "secondary"}
                                        style={!isEditPassword ? { backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' } : { backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c' }}
                                    >{!isEditPassword ? 'Change Password' : 'Cancel'}</Button>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <Container maxWidth='md'>
                                <Grid container={true} spacing={4}  style={{marginTop:30,}}>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true}>
                                            <InputLabel htmlFor="firstname">First Name</InputLabel>
                                            <Input value={firstname ? firstname : ''} onChange={this.onhandleChangeForm} id="firstname" name="firstname"
                                                onBlur={() => { this.validator.showMessageFor('First Name'); }} disabled={!isEnableEdit}
                                            />
                                            {isEnableEdit && this.validator.message('First Name', firstname, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true}>
                                            <InputLabel htmlFor="lastname">Last Name</InputLabel>
                                            <Input value={lastname ? lastname : ''} onChange={this.onhandleChangeForm} id="lastname" name="lastname"
                                                onBlur={() => { this.validator.showMessageFor('Last Name'); }} disabled={!isEnableEdit}
                                            />
                                            {isEnableEdit && this.validator.message('Last Name', lastname, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true}>
                                            <InputLabel htmlFor="email">Email</InputLabel>
                                            <Input value={email ? email : ''} onChange={this.onhandleChangeForm} id="email" name="email"
                                                onBlur={() => this.validator.showMessageFor('Email')} disabled={!isEnableEdit}
                                            />
                                            {isEnableEdit && this.validator.message('Email', email, 'required|email')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true}>
                                            <InputLabel htmlFor="mobileNumber">Phone Number</InputLabel>
                                            <PhoneNumberMaskInput value={mobileNumber ? mobileNumber : ''} onChange={this.onhandleChangeForm} id="mobileNumber" name="mobileNumber"
                                                disabled={!isEnableEdit} />

                                            {/* <Input value={mobileNumber ? mobileNumber : ''} onChange={this.onhandleChangeForm} id="mobileNumber" name="mobileNumber"
                                                onBlur={() => this.validator.showMessageFor('PhoneNumber')} disabled={!isEnableEdit}
                                            />*/}
                                            {isEnableEdit && this.validator.message('PhoneNumber', mobileNumber, 'required|phone')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true}>
                                            <InputLabel htmlFor="landlineNumber">Landline Number</InputLabel>
                                            <PhoneNumberMaskInput value={landlineNumber ? landlineNumber : ''} onChange={this.onhandleChangeForm} id="landlineNumber" name="landlineNumber"
                                                onBlur={() => this.validator.showMessageFor('landline Number')} disabled={!isEnableEdit}
                                            />
                                            {isEnableEdit && this.validator.message('landline Number', landlineNumber, 'required|phone')}
                                        </FormControl>
                                    </Grid>
                                    {isEditPassword &&
                                        <>
                                            <Grid item={true} xs={12} sm={6}>
                                                <TextField
                                                    variant="standard"
                                                    required={true}
                                                    fullWidth={true}
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    value={password ? password : ''}
                                                    onChange={this.onhandleChangeForm}
                                                    onBlur={() => this.validator.showMessageFor('password')}
                                                />
                                                {(password && !reg.test(password)) && <Typography className="srv-validation-message"> The password should min 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character.</Typography>}
                                                {isEditPassword && this.validator.message('password', password, isEditPassword ? 'required|min:8' : '')}
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <TextField
                                                    variant="standard"
                                                    required={true}
                                                    fullWidth={true}
                                                    id="confirmPassword"
                                                    type="password"
                                                    label="Confirm Password"
                                                    name="confirmPassword"
                                                    autoComplete="confirmPassword"
                                                    value={confirmPassword ? confirmPassword : ''}
                                                    onChange={this.onhandleChangeForm}
                                                    onBlur={() => this.validator.showMessageFor('confirm password')}
                                                />
                                                {isEditPassword && this.validator.message('confirm password', confirmPassword, isEditPassword ? 'required|min:8' : '')}
                                                {(password && confirmPassword) && (password !== confirmPassword) &&
                                                    <Typography className="srv-validation-message">Your password and confirmation password do not match.</Typography>}
                                            </Grid>
                                        </>
                                    }
                                    <Grid item={true} xs={12} sm={12}>
                                        <Grid container={true} spacing={3} className={classes ? classes.alignButton : ''}>
                                            <Grid item={true} xs={12} sm={6}>
                                                <Button
                                                    className={classes ? classes.buttonCommon : ''}
                                                    onClick={(event) => isEnableEdit ? this.handleSubmit(event) : this.setState({ isEnableEdit: true })}
                                                    variant="text"
                                                    color="primary"
                                                    style={{
                                                        backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c'

                                                    }}  >{isEnableEdit ? 'Update' : 'Edit Profile'}</Button>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={6}>
                                                <Button
                                                    className={classes ? classes.buttonCommon : ''}
                                                    onClick={this.handleCancel}
                                                    variant="text"
                                                    color="secondary"
                                                    style={{ backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c' }}>Cancel</Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Typography>)
    }
}

const styles = (theme: Theme) => ({
    container: {
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    alignCard: {
        [theme.breakpoints.between('md', 'xl',)]: {
            paddingRight: '10px'
        },
        [theme.breakpoints.between('sm', 'md',)]: {
            paddingRight: '10px'
        },
        [theme.breakpoints.between('xs','sm')]: {
            paddingRight: '0px'
        },
    },
    formTitle: {

        fontWeight: 'bold'
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    alignGridMobileView: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
        },
    },
    sectionTitle: {
        marginBottom: "20px"
    }

});


const mapStateToProps = (state: any) => {
    return {
        userProfileData: state.userStore?.userProfileDetails
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getUserProfile: UserActions.getUserProfileById,
        putUpdateUserById: UserActions.putUpdateUserById,
        resetProfileStore: UserActions.resetProfileStore
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProfilePage as any)) as any);