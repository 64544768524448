import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteAccess } from '../AppConstants';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';

const SideNavOptions = [
  // { path: '/menu', title: 'Menu', icon: () => <FontAwesomeIcon icon={faUserShield} />, access: RouteAccess.Administrator },
  // { path: '/sensor', title: 'Sensor', icon: () => <FontAwesomeIcon icon={faListAlt} />, access: RouteAccess.Administrator },
  // { path: '/boot-loader', title: 'Boot Loader', icon: () => <FontAwesomeIcon icon={faPager} />, access: RouteAccess.Administrator },
  { path: '/unwired-product', title: 'Products', icon: () => <FontAwesomeIcon icon={faProductHunt as any} />, access: RouteAccess.AdminAndContributor },
];

export default SideNavOptions;